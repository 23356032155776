<template>
  <MyView :meta="{ viewClass: 'view-comments', inFx: 'rightIn' }" ref="MyView">
    <ViewHeader icon="arrowLeft" :title="$locale['words']['comments']" @back="goBack">
      <div slot="right" class="flex-center">
        <a class="view-header-button"><UserCreditsBtn /></a>
        <HamburgerBtn />
      </div>
    </ViewHeader>
    <ViewContent @scroll.native="scrolling">
      <div class="view-comments-content">
        <div class="view-comments-main main">
          <div class="view-comments-list">
            <CommentsViewCard v-for="(item, index) in items" :key="index" :item="item" />
          </div>
          <ViewMoreBtn :morepage="morepage" :loading="loading" @click="getComments((page += 1))" />
        </div>
      </div>
    </ViewContent>
    <CommentBox :post="$route.query.comments" />
  </MyView>
</template>

<script>
export default {
  components: {
    MyView: () => import("../../views/View.vue"),
    ViewHeader: () => import("../view/ViewHeader.vue"),
    ViewContent: () => import("../view/ViewContent.vue"),
    HamburgerBtn: () => import("../home/HamburgerBtn.vue"),
    UserCreditsBtn: () => import("../user/UserCreditsBtn.vue"),
    ViewMoreBtn: () => import("../widgets/ViewMoreBtn.vue"),
    CommentsViewCard: () => import("./CommentsViewCard.vue"),
    CommentBox: () => import("./CommentBox.vue"),
  },
  data: function() {
    return {
      loading: true,
      page: 1,
      limit: 12,
    };
  },
  methods: {
    getComments: async function(page) {
      this.isLoading(true);
      try {
        page = page >= 1 ? page : 1;
        let query = `${this.$route.query.sort ? `&sort=${this.$route.query.sort}` : ""}`;
        query += `post=${this.$route.query.comments}&page=${page}&limit=${this.limit}${query}`;
        await this.$store.dispatch("getComments", { page, query });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    scrolling: function($event) {
      this.$scroller.isBottomScroll($event, () => {
        if (!this.loading && this.morepage) {
          this.getComments((this.page += 1));
        }
      });
    },
  },
  beforeMount: function() {
    this.getComments((this.page = 1));
  },
  computed: {
    items: function() {
      return this.$store.state.comments.comments;
    },
    morepage: function() {
      return this.$store.state.comments.moreComments;
    },
  },
};
</script>

<style lang="scss">
.view-comments {
  z-index: 12;
  .main-view-content {
    background-color: #fff;
  }
  &-list {
    margin-bottom: 49px;
    > [deep="0"]:not(:last-child) {
      border-bottom: solid 1px $alto;
    }
  }
}
</style>
